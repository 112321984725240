import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Sidebar from './components/Sidebar';
import EmployeeTable from './components/EmployeeTable';
import Navbar from './components/Navbar';
import './App.css';
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, getDoc,onSnapshot, doc, updateDoc, deleteDoc, serverTimestamp, query, orderBy, where, deleteField } from "firebase/firestore";
import { getAuth, signInWithPopup, OAuthProvider, onAuthStateChanged } from "firebase/auth";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from './contexts/ThemeContext';
import { useTheme } from './contexts/ThemeContext';
import './Assets/scss/Modern.scss';
import { getUserPhoto, isTokenExpired, refreshAccessToken } from './utils/microsoftGraph';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBN-GhZCT0lX7jQsabvDTBtXxLxT42OOoI",
  authDomain: "inout-board-2553c.firebaseapp.com",
  projectId: "inout-board-2553c",
  storageBucket: "inout-board-2553c.firebasestorage.app",
  messagingSenderId: "458913498670",
  appId: "1:458913498670:web:a74451bc369c3b111ac1bf",
  measurementId: "G-91LZPWKDHM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
    tenant: '0acc6366-4190-46f0-8f2a-47472e0be81f'
});

// Add these scopes to request access to user profile and photo
provider.addScope('user.read');
provider.addScope('profile');
provider.addScope('openid');
//provider.addScope('presence.read');

const auth = getAuth();
export const auth1 = auth;

// Move this sign-in logic into a function so we can call it when needed
export const signInWithMicrosoft = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    // Get the OAuth access token
    const credential = OAuthProvider.credentialFromResult(result);
    const accessToken = credential.accessToken;
    
    if (result.user && accessToken) {
      //console.log('Access Token obtained:', accessToken);
      
      // Find the employee document by email
      const employeesRef = collection(db, 'Employees');
      const q = query(employeesRef, where('Username', '==', result.user.email));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const employeeDoc = querySnapshot.docs[0];
        // Store the token in a separate document in the tokens collection
        await updateDoc(doc(db, 'tokens', employeeDoc.id), {
          accessToken,
          lastTokenUpdate: serverTimestamp(),
          userId: employeeDoc.id,
          email: result.user.email
        });
      }
      
      // Try to get the photo right after sign-in
      const photoUrl = await getUserPhoto(accessToken);
      //console.log('Photo URL:', photoUrl);
      
      return { 
        user: result.user, 
        accessToken,
        photoUrl 
      };
    }
    return { user: result.user };
  } catch (error) {
    console.error('Sign-in error:', error);
    throw error;
  }
};

// Add this helper function at the top of the file, outside of any component
const getTimeAgo = (timestamp) => {
  if (!timestamp || !timestamp.toDate) {
    return 'now';
  }

  const now = new Date();
  const then = timestamp.toDate(); // Convert Firestore timestamp to Date
  const diffInSeconds = Math.floor((now - then) / 1000);

  if (diffInSeconds < 60) {
    return 'now';
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes}m ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays}d ago`;
};

// Add this custom hook at the top of the file
const useTimeAgo = (timestamp) => {
  const [timeAgo, setTimeAgo] = useState(() => getTimeAgo(timestamp));

  useEffect(() => {
    if (!timestamp) return; // Add early return if no timestamp

    const interval = setInterval(() => {
      setTimeAgo(getTimeAgo(timestamp));
    }, 30000); // Update every 30 seconds

    return () => clearInterval(interval);
  }, [timestamp]);

  return timeAgo;
};

// Modify the toast component to be its own component
const StatusChangeToast = ({ newData }) => {
  const timeAgo = useTimeAgo(newData.LastUpdated);
  
  const getStatusBadge = (status) => {
    const variants = {
      'In': 'success',
      'Out': 'danger',
      'Lunch': 'warning',
      'Meeting': 'danger',
      'Vacation': 'danger'
    };
    return <h4 className='m-0'><Badge bg={variants[status] || 'secondary'} text={status==='Lunch' ? 'primary' : ''}>{status}</Badge></h4>;
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2 border-bottom toast-header">
        <strong className="fs-5">Status Change</strong>
        <small>{timeAgo}</small>
      </div>
      <div className="d-flex align-items-center">
        <span><span className="fs-5">{newData.FirstName} {newData.LastName}</span> changed status to  </span>
        <div className="mx-1">{getStatusBadge(newData.Status)}</div>
      </div>
      {newData.Comment && (
        <div className="mt-1">
          <small>{newData.Comment}</small>
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

function AppContent() {
  const { isDarkMode } = useTheme();
  
  const [employees, setEmployees] = useState([]);
  const [user, setUser] = useState(null);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [previousEmployees, setPreviousEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const previousEmployeesRef = useRef({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [pinnedEmployees, setPinnedEmployees] = useState(() => {
    const saved = localStorage.getItem('pinnedEmployeeIds');
    return saved ? employees.filter(emp => JSON.parse(saved).includes(emp.id)) : [];
  });

  const loadCachedData = () => {
    try {
      const cached = {
        employees: JSON.parse(localStorage.getItem('cachedEmployees')) || [],
        departments: JSON.parse(localStorage.getItem('cachedDepartments')) || [],
        lastSync: localStorage.getItem('lastSync')
      };

      console.log('Cache status:', {
        hasCachedEmployees: Array.isArray(cached.employees) && cached.employees.length > 0,
        employeesCount: cached.employees?.length || 0,
        hasCachedDepartments: Array.isArray(cached.departments) && cached.departments.length > 0,
        departmentsCount: cached.departments?.length || 0,
        lastSync: cached.lastSync,
        cacheAge: cached.lastSync ? `${(new Date() - new Date(cached.lastSync)) / 1000} seconds` : 'no cache'
      });

      if (Array.isArray(cached.employees) && cached.employees.length > 0) {
        console.log('Using cached employees data');
        setEmployees(cached.employees);
        // Set admin status from cached data
        if (user) {
          const currentEmployee = cached.employees.find(emp => emp.Username === user.email);
          if (currentEmployee) {
            console.log('Setting admin status from cache:', {
              employeeId: currentEmployee.id,
              isAdmin: currentEmployee.isAdmin || false
            });
            setCurrentEmployeeId(currentEmployee.id);
            setIsAdmin(currentEmployee.isAdmin || false);
          }
        }
      }
      if (Array.isArray(cached.departments) && cached.departments.length > 0) {
        console.log('Using cached departments data');
        setDepartments(cached.departments);
      }

      return cached.lastSync;
    } catch (error) {
      console.error('Error loading cached data:', error);
      return null;
    }
  };

  useEffect(() => {
    setPinnedEmployees(prev => {
      const saved = localStorage.getItem('pinnedEmployeeIds');
      if (saved && employees.length > 0) {
        const pinnedIds = JSON.parse(saved);
        return employees.filter(emp => pinnedIds.includes(emp.id));
      }
      return prev;
    });
  }, [employees]);

  useEffect(() => {
    if (!user || isLoading) return;

    const lastSync = loadCachedData();
    const cleanup = fetchUpdatedData(lastSync);

    return () => {
      if (cleanup) cleanup();
    };
  }, [user, isLoading]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth1, async (user) => {
      if (user) {
        try {
          //console.log('User authenticated:', user.email);
          setIsLoading(true);

          const employeesRef = collection(db, 'Employees');
          const q = query(employeesRef, where('Username', '==', user.email));
          const querySnapshot = await getDocs(q);
          
          let updatedUser = {
            ...user,
            displayName: user.displayName,
            email: user.email
          };

          if (!querySnapshot.empty) {
            const employeeDoc = querySnapshot.docs[0];
            updatedUser.employeeId = employeeDoc.id;

            try {
              const tokenDoc = await getDoc(doc(db, 'tokens', user.email));
              
              if (tokenDoc.exists()) {
                const tokenData = tokenDoc.data();
                // console.log('Token found, checking validity...');
                
                if (tokenData?.accessToken) {
                  // Check if token is expired
                  const isExpired = await isTokenExpired(tokenData.accessToken);
                  
                  if (!isExpired) {
                    // console.log('Token is valid, fetching photo...');
                    try {
                      const photoUrl = await getUserPhoto(tokenData.accessToken);
                      if (photoUrl) {
                        updatedUser.photoUrl = photoUrl;
                      }
                    } catch (photoError) {
                      // console.log('Error fetching photo:', photoError);
                      throw new Error('Photo fetch failed');
                    }
                  } else {
                    // console.log('Token is expired, re-authenticating...');
                    throw new Error('Token expired');
                  }
                }
              }
              
              // If we get here without a valid token and photo, we need to re-authenticate
              if (!updatedUser.photoUrl) {
                // console.log('No valid token found, authenticating...');
                const result = await signInWithMicrosoft();
                if (result?.photoUrl) {
                  updatedUser.photoUrl = result.photoUrl;
                }
              }
            } catch (tokenError) {
              // console.log('Token error, triggering re-auth:', tokenError);
              const result = await signInWithMicrosoft();
              if (result?.photoUrl) {
                updatedUser.photoUrl = result.photoUrl;
              }
            }
          }
          
          setUser(updatedUser);
        } catch (error) {
          console.error('Error during auth state change:', error);
          setUser(user);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(true);
        // console.log('No user signed in, triggering sign-in...');
        try {
          const result = await signInWithMicrosoft();
          if (result?.user) {
            setUser({
              ...result.user,
              photoUrl: result.photoUrl
            });
          }
        } catch (error) {
          console.error('Auto sign-in failed:', error);
        } finally {
          setIsLoading(false);
        }
      }
    });

    return () => unsubscribe();
  }, []);
  
  useEffect(() => {
    if (!user || isLoading) return;
    
    const q = query(collection(db, "Departments"), orderBy("Rank"));
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const departmentsArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDepartments(departmentsArray);
    });

    return () => unsubscribe();
  }, [user, isLoading]);
  
  if (user) {
    // Access user profile data
    //console.log(user.displayName); 
    //console.log(user.email); 
    //console.log(user.photoURL); 
  }
  
  const updateEmployeeStatus = (status, comment) => {
    setEmployees(prevEmployees => 
      prevEmployees.map(emp => 
        emp.id === currentEmployeeId 
          ? { 
              ...emp, 
              Status: status,  // Make sure field names match Firestore
              Comment: comment,
              LastUpdated: new Date()
            }
          : emp
      )
    );
  };

  const handleEditEmployee = async (updatedEmployee) => {
    try {
      const employeeRef = doc(db, 'Employees', updatedEmployee.id);
      
      // Prepare the update data
      const updateData = {
        FirstName: updatedEmployee.FirstName,
        LastName: updatedEmployee.LastName,
        Extension: updatedEmployee.Extension,
        Comment: updatedEmployee.Comment,
        Status: updatedEmployee.Status,
        LastUpdated: serverTimestamp()
      };

      // Update the document in Firestore
      await updateDoc(employeeRef, updateData);

      // Show success message (optional)
      toast.success('Employee updated successfully');
    } catch (error) {
      console.error('Error updating employee:', error);
      // Show error message (optional)
      toast.error('Failed to update employee');
    }
  };

  const handleDeleteEmployee = async (employeeId) => {
    try {
      const employeeRef = doc(db, 'Employees', employeeId);
      await deleteDoc(employeeRef);
    } catch (error) {
      console.error('Error deleting employee:', error);
    }
  };

  const handleTogglePin = (employee) => {
    setPinnedEmployees(prev => {
      const newPinnedEmployees = prev.some(emp => emp.id === employee.id)
        ? prev.filter(emp => emp.id !== employee.id)
        : [...prev, employee];
      
      // Save IDs to localStorage
      localStorage.setItem('pinnedEmployeeIds', JSON.stringify(
        newPinnedEmployees.map(emp => emp.id)
      ));
      
      return newPinnedEmployees;
    });
  };

  const fetchUpdatedData = async (lastSync) => {
    setIsSyncing(true);
    try {
      const lastSyncDate = lastSync ? new Date(lastSync) : new Date(0);
      
      const shouldFetchAll = !lastSync || 
        (new Date() - new Date(lastSync) > 24 * 60 * 60 * 1000);

      console.log('Fetch strategy:', {
        shouldFetchAll,
        lastSync,
        lastSyncDate,
        timeSinceLastSync: lastSync ? `${(new Date() - new Date(lastSync)) / 1000} seconds` : 'never'
      });

      // Initial data fetch if needed
      if (shouldFetchAll) {
        // ... existing shouldFetchAll code ...
      }

      // Set up real-time listeners for updates
      const updatesQuery = query(
        collection(db, "Employees"),
        where("LastUpdated", ">", lastSyncDate)
      );

      console.log('Query details:', {
        collectionPath: collection(db, "Employees").path,
        lastSyncDate: lastSyncDate.toISOString(),
        filters: updatesQuery._query?.filters?.map(f => ({
          field: f.field?.segments?.join('.'),
          op: f.op?.name,
          value: f.value instanceof Date ? f.value.toISOString() : f.value
        })) || []
      });

      console.log('Setting up employee snapshot listener');

      const unsubscribeEmployees = onSnapshot(updatesQuery, (snapshot) => {
        if (!snapshot.metadata.hasPendingWrites) {
          snapshot.docChanges().forEach(change => {
            const newData = { id: change.doc.id, ...change.doc.data() };
            
            setEmployees(prevEmployees => {
              // Find old data from previous state
              const oldData = prevEmployees?.find?.(emp => emp.id === change.doc.id);

              // Debug status change conditions
              console.log('Status change check:', {
                employeeName: `${newData.FirstName} ${newData.LastName}`,
                hasOldData: !!oldData,
                oldStatus: oldData?.Status,
                newStatus: newData.Status,
                statusChanged: oldData && oldData.Status !== newData.Status,
                isCurrentUser: newData.id === currentEmployeeId,
                isUserEmail: newData.Username === user?.email,
                shouldNotify: oldData && 
                  oldData.Status !== newData.Status && 
                  newData.id !== currentEmployeeId && 
                  newData.Username !== user?.email
              });

              // Show toast notification if conditions are met
              if (oldData && 
                  oldData.Status !== newData.Status && 
                  newData.id !== currentEmployeeId && 
                  newData.Username !== user?.email) {
                console.log('Showing toast for status change:', {
                  employee: `${newData.FirstName} ${newData.LastName}`,
                  oldStatus: oldData.Status,
                  newStatus: newData.Status
                });
                
                toast(
                  <StatusChangeToast newData={newData} />,
                  {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
              }

              // Update employees array
              const updated = Array.isArray(prevEmployees) ? [...prevEmployees] : [];
              const index = updated.findIndex(e => e.id === newData.id);
              if (index >= 0) {
                updated[index] = {
                  ...newData,
                  DeptRef: newData.DeptRef ? {
                    id: newData.DeptRef.id,
                    path: newData.DeptRef.path
                  } : null
                };
              } else {
                updated.push({
                  ...newData,
                  DeptRef: newData.DeptRef ? {
                    id: newData.DeptRef.id,
                    path: newData.DeptRef.path
                  } : null
                });
              }
              
              const sorted = updated.sort((a, b) => {
                if (a.DeptRef?.id !== b.DeptRef?.id) {
                  return (a.DeptRef?.id || '').localeCompare(b.DeptRef?.id || '');
                }
                return (a.Rank || 0) - (b.Rank || 0);
              });

              localStorage.setItem('cachedEmployees', JSON.stringify(sorted));
              return sorted;
            });
          });
        }
      });

      // Set up departments listener
      const deptQuery = shouldFetchAll 
        ? query(collection(db, "Departments"), orderBy("Rank"))
        : query(
            collection(db, "Departments"),
            where("LastUpdated", ">", lastSyncDate)
          );

      console.log('Department query details:', {
        collectionPath: collection(db, "Departments").path,
        lastSyncDate: lastSyncDate.toISOString()
      });

      const unsubscribeDepartments = onSnapshot(deptQuery, (snapshot) => {
        if (!snapshot.metadata.hasPendingWrites) {
          // ... existing department update code ...
        }
      });

      // Update lastSync time
      const now = new Date().toISOString();
      localStorage.setItem('lastSync', now);

      setIsSyncing(false);
      return () => {
        unsubscribeEmployees();
        unsubscribeDepartments();
      };
    } catch (error) {
      console.error('Error fetching updated data:', error);
      setIsSyncing(false);
    }
  };

  // Show loading state
  if (isLoading) {
    return (
      <Container className="mt-5">
        <Card className="text-center shadow">
          <Card.Body className="p-5">
          <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <Card.Title>Loading...</Card.Title>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  // If user is not authenticated, show access denied
  if (!user) {
    return (
      <Container className="mt-5">
        <Card className="text-center shadow">
          <Card.Body className="p-5">
            <Card.Title as="h1">Access Denied</Card.Title>
            <Card.Text>
              Unable to sign in with your Microsoft account. Please refresh the page to try again.
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  // Find current employee and their status
  const currentEmployee = employees.find(emp => emp.Username === user?.email);
  const currentStatus = currentEmployee?.Status || 'In';
  //console.log('App currentUser:', user);
    //console.log('App departments:', departments);
  return (
    <>
      <div id="app" data-bs-theme={isDarkMode ? 'dark' : 'light'} className={isDarkMode ? 'bgwcdark' : 'bgwclight'}>
        <Navbar 
          currentUser={{
            ...currentEmployee,
            photoUrl: user?.photoUrl
          }}
          isAdmin={isAdmin} 
          departments={departments}
        />
        <Container className="p-0">
          <Row className="g-0" style={{ minHeight: '100vh' }}>
            <Col md={4} lg={3} xl={3} xxl={3} className="d-flex flex-column">
              <Sidebar 
                isAdmin={isAdmin} 
                employees={employees}
                pinnedEmployees={pinnedEmployees}
                onTogglePin={handleTogglePin}
                departments={departments}
              />
            </Col>
            <Col md={8} lg={9} xl={9} xxl={9} className="d-flex">
              <Container className="py-3 px-3 bg-body-tertiary">
                {/* <Row>
                  <Col md={currentEmployee ? 7 : 12} className="d-flex">
                    <Card className="w-100 bg-transparent shadow-none">
                      <Card.Body>
                        <h2>Wabash Center InOut Board</h2>
                        <Card.Text>Welcome, {user.displayName}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  
                  {currentEmployee && (
                    <Col className="d-flex">
                      <StatusControl 
                        employeeId={currentEmployeeId} 
                        currentUserId={currentEmployeeId}
                        isAdmin={isAdmin}
                        employeeName={user.displayName}
                        currentStatus={currentEmployee.Status || 'In'}
                        profilePhotoUrl={user.photoUrl}
                      />
                    </Col>
                  )}
                </Row> */}
                
                {user && !isLoading && (
                  <EmployeeTable 
                    employees={employees}
                    departments={departments}
                    isAdmin={isAdmin}
                    currentUserId={currentEmployeeId}
                    onEdit={handleEditEmployee}
                    onDelete={handleDeleteEmployee}
                    onTogglePin={handleTogglePin}
                    setEmployees={setEmployees}
                  />
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer 
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={isDarkMode ? 'dark' : 'light'}
        progressClassName="Toastify__progress-bar--info"
      />
    </>
  );
}

export default App; 