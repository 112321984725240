import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Badge, Card, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTheme } from '../contexts/ThemeContext';
import { FaChevronDown, FaChevronUp, FaPencilAlt } from 'react-icons/fa';
import { updateDoc, doc, writeBatch, serverTimestamp } from 'firebase/firestore';
import { db } from '../App.jsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { GripVertical, PinAngleFill } from 'react-bootstrap-icons';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

const pinButtonStyle = {
  position: 'relative',
  display: 'inline-block',
  marginLeft: '8px',
  visibility: 'hidden'
};

const rowHoverStyle = {
  '&:hover .pin-button': {
    visibility: 'visible'
  }
};

function EmployeeTable({ 
  employees, 
  departments, 
  isAdmin, 
  currentUserId, 
  onEdit, 
  onDelete, 
  onTogglePin,
  setEmployees
}) {
  const [editingId, setEditingId] = useState(null);
  const [editForm, setEditForm] = useState({});
  const { isDarkMode } = useTheme();
  const [collapsedDepartments, setCollapsedDepartments] = useState(() => {
    const saved = localStorage.getItem('collapsedDepartments');
    if (saved) {
      return JSON.parse(saved);
    }
    
    return departments.reduce((acc, dept) => {
      acc[dept.id] = true;
      return acc;
    }, {});
  });
  const [showActionsId, setShowActionsId] = useState(null);
  const [isDragMode, setIsDragMode] = useState(false);

  useEffect(() => {
    setCollapsedDepartments(prev => {
      const updated = { ...prev };
      let hasChanges = false;
      
      departments.forEach(dept => {
        if (updated[dept.id] === undefined) {
          updated[dept.id] = true;
          hasChanges = true;
        }
      });

      return hasChanges ? updated : prev;
    });
  }, [departments]);

  useEffect(() => {
    localStorage.setItem('collapsedDepartments', JSON.stringify(collapsedDepartments));
  }, [collapsedDepartments]);

  const toggleDepartment = (deptId) => {
    setCollapsedDepartments(prev => ({
      ...prev,
      [deptId]: !prev[deptId]
    }));
  };

  const groupedEmployees = employees
    .filter(employee => employee.Visible !== false)
    .reduce((groups, employee) => {
      const deptId = employee.DeptRef?.id || 'unassigned';
      if (!groups[deptId]) {
        groups[deptId] = [];
      }
      groups[deptId].push(employee);
      return groups;
    }, {});

  Object.keys(groupedEmployees).forEach(deptId => {
    groupedEmployees[deptId].sort((a, b) => (a.Rank || 0) - (b.Rank || 0));
  });

  const sortedDepartments = departments
    .filter(dept => dept.Visible !== false)
    .sort((a, b) => (a.Rank || 0) - (b.Rank || 0));

  const getStatusBadge = (status, lastUpdated) => {
    const variants = {
      'In': 'success',
      'Out': 'danger',
      'Lunch': 'warning',
      'Meeting': 'danger',
      'Vacation': 'danger'
    };

    let formattedDate = 'No update time available';
    if (lastUpdated?.toDate) {
      const date = lastUpdated.toDate();
      const today = new Date();
      if (date.toDateString() === today.toDateString()) {
        formattedDate = `Today, ${format(date, 'h:mm a')}`;
      } else {
        formattedDate = format(date, 'PP, h:mm a');
      }
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip className="custom-tooltip">
            Last updated: {formattedDate}
          </Tooltip>
        }
      >
        <h4 className='m-0'>
          <Badge bg={variants[status] || 'secondary'} text={isDarkMode ? 'primary' : ''}>
            {status}
          </Badge>
        </h4>
      </OverlayTrigger>
    );
  };

  const handleEditClick = (employee) => {
    setEditingId(employee.id);
    setEditForm({
      FirstName: employee.FirstName,
      LastName: employee.LastName,
      Extension: employee.Extension,
      Comment: employee.Comment,
      Status: employee.Status
    });
  };

  const handleSaveClick = async (employeeId) => {
    try {
      await onEdit({ id: employeeId, ...editForm });
      setEditingId(null);
      setEditForm({});
      setShowActionsId(null);
      setIsDragMode(false);
    } catch (error) {
      console.error('Error updating employee:', error);
      toast.error('Failed to update employee', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditForm({});
    setShowActionsId(null);
    setIsDragMode(false);
  };

  const toggleActions = (employeeId, event) => {
    event.stopPropagation();
    setShowActionsId(showActionsId === employeeId ? null : employeeId);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const deptId = source.droppableId;

    const deptEmployees = employees
      .filter(emp => emp.DeptRef?.id === deptId)
      .sort((a, b) => (a.Rank || 0) - (b.Rank || 0));

    const reorderedEmployees = Array.from(deptEmployees);
    const [movedEmployee] = reorderedEmployees.splice(source.index, 1);
    reorderedEmployees.splice(destination.index, 0, movedEmployee);

    const updatedEmployees = employees.map(emp => {
      if (emp.DeptRef?.id !== deptId) return emp;
      const newIndex = reorderedEmployees.findIndex(reorderedEmp => reorderedEmp.id === emp.id);
      return newIndex !== -1 ? { ...emp, Rank: newIndex } : emp;
    });

    setEmployees(updatedEmployees);

    try {
      const batch = writeBatch(db);
      
      reorderedEmployees.forEach((emp, index) => {
        const employeeRef = doc(db, 'Employees', emp.id);
        batch.update(employeeRef, { 
          Rank: index,
          LastUpdated: serverTimestamp()
        });
      });

      await batch.commit();
    } catch (error) {
      console.error('Error updating ranks:', error);
      toast.error('Failed to update employee order', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    handleCancelEdit();
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="department-tables">
        {sortedDepartments.map(department => (
          <Card key={department.id} className="mb-4">
            <Card.Header 
              as="h5" 
              className={`${isDarkMode ? 'bgtabledark' : 'bgtablelight'} p-1 d-flex justify-content-between align-items-center rounded-top-3`}
              style={{ cursor: 'pointer' }}
              onClick={() => toggleDepartment(department.id)}
            >
              <span>{department.Name}</span>
              <Button 
                variant="link" 
                className="p-0 text-light" 
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDepartment(department.id);
                }}
              >
                {collapsedDepartments[department.id] ? <FaChevronUp /> : <FaChevronDown />}
              </Button>
            </Card.Header>
            <Collapse in={collapsedDepartments[department.id]}>
              <div>
                <Card.Body className="p-0">
                  <Table hover responsive className="mb-0 table-sm table-striped">
                    <thead className={isDarkMode ? 'bg-info-lighten' : 'bg-info text-contrast'}>
                      <tr>
                        <th className={isDarkMode ? 'text-bg-info-lighten nameCol pb-0 pt-0 pl-1' : 'text-bg-info nameCol pb-0 pt-0 pl-1'}>Name</th>
                        <th className={isDarkMode ? 'text-bg-info-lighten extCol p-0' : 'text-bg-info extCol p-0'}>Extension</th>
                        <th className={isDarkMode ? 'text-bg-info-lighten commentCol p-0' : 'text-bg-info commentCol p-0'}>Comment</th>
                        <th className={isDarkMode ? 'text-bg-info-lighten statusCol p-0 text-center' : 'text-bg-info statusCol p-0 text-center'}>Status</th>
                        {isAdmin && <th className={isDarkMode ? 'text-bg-info-lighten actionsCol p-0 pe-2' : 'text-bg-info actionsCol p-0 pe-2'}>Actions</th>}
                      </tr>
                    </thead>
                    <Droppable droppableId={department.id}>
                      {(provided) => (
                        <tbody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {(groupedEmployees[department.id] || []).map((employee, index) => (
                            <Draggable
                              key={employee.id}
                              draggableId={employee.id}
                              index={index}
                              isDragDisabled={!isAdmin}
                            >
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className={`
                                    ${employee.Status !== 'In' ? 'fw-bold' : 'fw-normal'}
                                    ${snapshot.isDragging ? 'bg-light' : ''}
                                    employee-row
                                  `}
                                  style={{
                                    ...provided.draggableProps.style,
                                    ...rowHoverStyle
                                  }}
                                >
                                  <td className="p-0 ps-1">
                                    {isAdmin && isDragMode && (
                                      <span
                                        {...provided.dragHandleProps}
                                        className="me-2 text-secondary float-start"
                                        style={{ cursor: 'move' }}
                                      >
                                        <GripVertical />
                                      </span>
                                    )}
                                    {editingId === employee.id ? (
                                      <div className="d-flex gap-2">
                                        <Form.Control
                                          type="text"
                                          value={editForm.FirstName}
                                          onChange={(e) => setEditForm({...editForm, FirstName: e.target.value})}
                                          placeholder="First Name"
                                        />
                                        <Form.Control
                                          type="text"
                                          value={editForm.LastName}
                                          onChange={(e) => setEditForm({...editForm, LastName: e.target.value})}
                                          placeholder="Last Name"
                                        />
                                      </div>
                                    ) : (
                                      <div className="d-flex align-items-center">
                                        <div>
                                          {employee.FirstName} {employee.LastName}
                                          <small className="d-block text-body-secondary">
                                            {employee.CellPhone}
                                          </small>
                                        </div>
                                        <OverlayTrigger
                                          placement="right"
                                          overlay={
                                            <Tooltip>
                                              Pin to sidebar
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            variant="link"
                                            size="sm"
                                            className={`pin-button ${isDarkMode ? 'text-dark' : 'text-dark'}`}
                                            onClick={() => onTogglePin(employee)}
                                            style={pinButtonStyle}
                                          >
                                            <PinAngleFill className={employee.isPinned ? 'text-primary' : ''} />
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {editingId === employee.id ? (
                                      <Form.Control
                                        type="text"
                                        value={editForm.Extension}
                                        onChange={(e) => setEditForm({...editForm, Extension: e.target.value})}
                                      />
                                    ) : (
                                      employee.Extension
                                    )}
                                  </td>
                                  <td>
                                    {editingId === employee.id ? (
                                      <Form.Control
                                        type="text"
                                        value={editForm.Comment}
                                        onChange={(e) => setEditForm({...editForm, Comment: e.target.value})}
                                      />
                                    ) : (
                                      employee.Comment
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {editingId === employee.id ? (
                                      <Form.Select
                                        value={editForm.Status}
                                        onChange={(e) => setEditForm({...editForm, Status: e.target.value})}
                                      >
                                        <option value="In">In</option>
                                        <option value="Out">Out</option>
                                        <option value="Lunch">Lunch</option>
                                        <option value="Meeting">Meeting</option>
                                        <option value="Vacation">Vacation</option>
                                      </Form.Select>
                                    ) : (
                                      getStatusBadge(employee.Status, employee.LastUpdated)
                                    )}
                                  </td>
                                  {isAdmin && (
                                    <td>
                                      {editingId === employee.id ? (
                                        <>
                                          <Button 
                                            variant="success" 
                                            size="sm" 
                                            className="me-1 p-0 px-1"
                                            onClick={() => {
                                              handleSaveClick(employee.id);
                                              setShowActionsId(null);
                                            }}
                                          >
                                            Save
                                          </Button>
                                          <Button 
                                            variant="secondary" 
                                            size="sm"
                                            className="p-0 px-1"
                                            onClick={handleCancelEdit}
                                          >
                                            Cancel
                                          </Button>
                                        </>
                                      ) : (
                                        <div className="align-items-center me-2" style={{textAlign: 'right'}}>
                                          {showActionsId === employee.id ? (
                                            <>
                                              <Button 
                                                variant="outline-primary" 
                                                size="sm" 
                                                className="me-1 p-0 px-1"
                                                onClick={() => handleEditClick(employee)}
                                              >
                                                Edit
                                              </Button>
                                              <Button 
                                                variant="outline-danger" 
                                                size="sm"
                                                className="me-1 p-0 px-1"
                                                onClick={() => {
                                                  onDelete(employee.id);
                                                  setShowActionsId(null);
                                                }}
                                              >
                                                Delete
                                              </Button>
                                              <Button 
                                                variant="outline-secondary" 
                                                size="sm"
                                                className="me-1 p-0 px-1"
                                                onClick={() => {
                                                  setShowActionsId(null);
                                                  setIsDragMode(false);
                                                }}
                                              >
                                                Cancel
                                              </Button>
                                            </>
                                          ) : (
                                            <div className="align-items-center me-2" style={{textAlign: 'right'}}>
                                              {isAdmin && (
                                                <Button
                                                  variant="link"
                                                  size="sm"
                                                  className={isDarkMode ? "p-0 text-primary-lighten" : "p-0 text-primary"}
                                                  onClick={(e) => {
                                                    toggleActions(employee.id, e);
                                                    setIsDragMode(true);
                                                  }}
                                                >
                                                  <FaPencilAlt />
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </Table>
                </Card.Body>
              </div>
            </Collapse>
          </Card>
        ))}
      </div>
    </DragDropContext>
  );
}

export default EmployeeTable; 